.promotionalsSlider .swiper-pagination-bullet-active {
    background: var(--golden) !important;
}

.promotionalsSlider .sliding-image {
    max-width: 75% !important;
    min-height: 80vh !important;
    max-height: 80vh !important;
}

@media screen and (min-width: 320px) and (max-width: 420px) {
    .promotionalsSlider .sliding-image {
        max-width: 75% !important;
        min-height: 30vh !important;
        max-height: 30vh !important;
    }

    .promotionalsSlider h2 {
        font-size: 1.2rem !important;
    }

    .promotionalsSlider h4 {
        font-size: 1rem !important;
    }

    .promotionalsSlider p small {
        font-size: 0.9rem !important;
    }
}

@media screen and (min-width: 360px) and (max-width: 768px) {
    .promotionalsSlider .sliding-image {
        max-width: 75% !important;
        min-height: 25vh !important;
        max-height: 25vh !important;
    }

    .promotionalsSlider h2 {
        font-size: 1.2rem !important;
    }

    .promotionalsSlider h4 {
        font-size: 1rem !important;
    }

    .promotionalsSlider p small {
        font-size: 0.9rem !important;
    }
}