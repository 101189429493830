.radius-10 {
    border-radius: 10px;
}

.stake-font {
    font-size: 12px;
}

.percentage-font {
    font-size: 10px;
}

.stake-line-height p {
    line-height: 1.2;
}

.lottery-launcher .card,
.lottery-launcher-history .card,
.lottery-launcher-result .card {
    --bs-card-border-color: transparent !important;
    --bs-card-bg: transparent !important;
    --bs-card-border-width: none !important;
    --bs-card-border-radius: 10px;
}

.dark .lottery-launcher .card .game-item {
    background-color: var(--dark-gray);
    border-bottom: 1px solid var(--dark);
}

.light .lottery-launcher .card .game-item {
    background-color: var(--white);
    border-bottom: 1px solid var(--dark);
}

.lottery-launcher .card .game-item:last-child {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.dark .lottery-launcher .individual-type {
    background-color: var(--blackish-ash);
    border-bottom: 2px solid var(--dark-gray);
}

.light .lottery-launcher .individual-type {
    background-color: var(--placeholder);
    border-bottom: 2px solid var(--white);
}

.lottery-launcher .individual-type:last-child {
    border-bottom: none;
    border-radius: 0 0 10px 10px;
}

.dark .lottery-launcher-result .card .result-item {
    background-color: var(--dark);
    border-bottom: 1px solid var(--dark);
}

.light .lottery-launcher-result .card .result-item {
    background-color: var(--white);
    border-bottom: 1px solid var(--dark);
}

.lottery-launcher-result .card .result-item:last-child {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.dark .lottery-launcher-result .individual-type {
    background-color: var(--blackish-ash);
    border-bottom: 2px solid var(--dark);
    border-radius: 10px;
}

.light .lottery-launcher-result .individual-type {
    background-color: var(--gray);
    border-bottom: 2px solid var(--white);
    border-radius: 10px;
}

/* .lottery-launcher-result .individual-type:last-child {
    border-bottom: none;
    border-radius: 0 0 10px 10px;
} */

.lottery-launcher-result .result-item .live-text {
    border: 2px solid var(--golden);
    border-radius: 5px;
}

.lottery-launcher-result .result-item .result-item-icon {
    border-radius: 5px;
}

.dark .lottery-launcher-result .result-item .result-item-icon {
    background-color: var(--gray);
}

.light .lottery-launcher-result .result-item .result-item-icon {
    background-color: var(--white);
}

.lottery-launcher-result .result-item .result-item-name {
    font-size: 12px;
}

.dark .lottery-launcher-result .result-item .result-item-name {
    color: var(--white);
}

.light .lottery-launcher-result .result-item .result-item-name {
    color: var(--dark);
}

.lottery-launcher-result .result-item .win-item {
    background-color: var(--golden);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 10px;
}

.lottery-launcher-result .result-item .total {
    /* .lottery-launcher-result .result-item .total .total-count { */
    font-size: 10px;
}

.dark .lottery-launcher-result .result-item .total {
    color: var(--white);
}

.light .lottery-launcher-result .result-item .total {
    color: var(--dark);
}

.lottery-launcher-result .result-item .total .total-count {
    background-color: var(--dark-gray);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 10px;
}

@keyframes rotating-reverse {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating-reverse {
    -webkit-animation: rotating-reverse 2s linear infinite;
    -moz-animation: rotating-reverse 2s linear infinite;
    -ms-animation: rotating-reverse 2s linear infinite;
    -o-animation: rotating-reverse 2s linear infinite;
    animation: rotating-reverse 2s linear infinite;
}

/* lottery-place-modal */

/* Hide scrollbar for Chrome, Safari and Opera */
.lottery-place-modal::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.lottery-place-modal {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.lottery-place-modal .modal-content {
    /* min-height: calc(100vh - 38px) !important; */
    /* margin-top: 38px !important; */
    min-height: 100dvh !important;
    border: none !important;
}

.lottery-place-modal {
    min-width: 100%;
    /* padding: 0 !important; */
    --bs-modal-margin: 0 !important;
    --bs-modal-width: 100% !important;
    --bs-modal-border-radius: 10px 10px 0 0 !important;

    /* animation */
    animation-name: modalFadeFromBottomToUp !important;
    animation-duration: .8s;
}

.lottery-history-items {
    overflow-y: auto;
    scrollbar-color: var(--dark-gray) var(--gray);
    scrollbar-width: thin;
    max-height: 98dvh;
    height: 100%;
}

/* Webkit-based browsers (Chrome, Safari, Edge) */
.lottery-history-items::-webkit-scrollbar {
    width: 10px;
    /* Adjust width as needed */
}

.lottery-history-items::-webkit-scrollbar-track {
    background: var(--gray);
    /* Track color */
}

.lottery-history-items::-webkit-scrollbar-thumb {
    background-color: var(--golden);
    /* Thumb color */
    border-radius: 0 !important;
    /* Optional: Rounded corners */
    border: 2px solid var(--gray);
    /* Optional: Border around thumb */
}

/* 
.modal .lottery-history-items ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px var(--dark);
}

.modal .lottery-history-items ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 20px 10px var(--gray);
} */

@keyframes modalFadeFromBottomToUp {
    from {
        transform: translateY(+100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}


.dark .lottery-place-modal .lottery-place-input {
    background-color: var(--blackish-ash) !important;
    /* border-radius: 5px;
    width: 95%; */
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.75) !important;
}

.light .lottery-place-modal .lottery-place-input {
    background-color: var(--white) !important;
    /* border-radius: 5px;
    width: 95%; */
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25) !important;
}

.lottery-place-modal .lottery-place-input {
    text-align: center;
    border-radius: 5px;
    width: 95%;
}

.placeholder-red:disabled::-webkit-input-placeholder {
    /* WebKit browsers */
    color: var(--red);
}

.placeholder-red:disabled:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: var(--red);
}

.placeholder-red:disabled::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: var(--red);
}

.placeholder-red:disabled:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: var(--red);
}

/* .dark .lottery-place-modal input {
    background-color: var(--blackish-ash);
}

.light .lottery-place-modal input {
    background-color: var(--blackish-ash);
} */

table,
th,
td,
tr {
    border: none;
}

.dark .lottery-place-modal td {
    background-color: transparent !important;
}

.light .lottery-place-modal td {
    background-color: transparent !important;
}

.video-frame {
    border-radius: 5px;
    background: linear-gradient(to right, var(--golden), var(--golden));
    width: 100%;
    height: 325px;
    height: 'auto';
}

.video-frame iframe {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 5px;
}

.tv-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
}

.tv-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.tv-container-sticky {
    /* position: relative; */
    width: 75%;
    overflow: hidden;
    padding-top: 28%;
}

.tv-iframe-sticky {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50%;
    border: none;
}

.sticky {
    position: fixed;
    bottom: -53px !important;
    /* Adjust the desired distance from the bottom */
    right: 10px;
    /* Adjust the desired distance from the right */
    z-index: 10;
    /* Adjust the z-index as needed */
}

.hide-tv {
    position: absolute;
    z-index: 11;
    top: -30;
    right: 5px;
}

@media only screen and (max-width: 992px) {
    .video-frame {
        height: 180px;
    }

    .video-frame iframe {
        border-radius: 5px;
        padding: 3px;
    }
}

@media only screen and (min-width: 992px) {
    .lottery-place-modal {
        --bs-modal-margin: 0 !important;
        --bs-modal-width: 33% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}