.game-card .card {
    border-color: transparent !important;
    /* border-radius: none !important; */
    border-width: 0 !important;
    box-shadow: none !important;
    outline: none !important;
}

.game-card .game-card-title {
    font-size: 0.8rem !important;
}

.game-card .game-card-subtitle {
    font-size: 0.6rem !important;
}

.card-2 img,
.card-3 img {
    height: 120px;
}

@media screen and (max-width: 768px) {
    .card-2 img {
        height: 110px;
    }

    .card-3 img {
        height: 80px;
    }
}