.start-bg {
    /* background-color: #2A303E; */
    height: 100dvh;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    z-index: 11;
}

.light .start-bg {
    background-color: var(--white);
}

.dark .start-bg {
    /* background-color: #2A303E; */
    background-color: var(--dark);
}

.started-progress-bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
}

.progress-bar {
    font-weight: bold;
}

.light .progress-bar {
    background-color: var(--golden);
    color: var(--dark);
}

.dark .progress-bar {
    background-color: var(--golden);
    color: var(--dark);
}

.light .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, var(--dark) 25%, var(--dark) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, var(--dark) 75%, var(--dark));
}

.dark .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, var(--dark) 25%, var(--dark) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, var(--dark) 75%, var(--dark));
}

.shadow-image {
    width: 175px;
    /* Adjust size as needed */
    height: 175px;
    border-radius: 50%;
    /* Makes the image rounded */
    box-shadow: 0 0 20px 10px var(--golden);
    /* Initial shadow */
    animation: shadowGlow 1.3s infinite alternate;
    /* Animation */
}

@keyframes shadowGlow {
    0% {
        box-shadow: 0 0 20px 10px transparent;
    }

    100% {
        box-shadow: 0 0 20px 10px var(--golden);
        /* Slightly brighter and larger glow */
    }
}

@media (min-width: 992px) {
    .started-progress-bar {
        width: 33%;
    }
}