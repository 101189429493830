/* common navigation styles starts */

.header {
    height: 38px !important;
    position: relative;
}

.header .logo {
    height: 30px;
    width: auto;
}

.button,
.header-button {
    border-radius: 0.3em;
    font-weight: 500;
    font-size: 0.7em;
    padding: 0.2em 0.8em;
}

.custom-amount-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-radius: 3px;
    width: auto;
}

.currency-icon {
    color: var(--white);
    background-color: var(--light-green);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 0.9rem;
}

.custom-amount-button .amount-deposit-button {
    margin-left: 6px;
    background-color: var(--light-green);
    border-radius: 0 3px 3px 0;
    padding: 2px;
}


.bet-notification-number {
    position: relative;
}

.bet-notification-number span {
    position: absolute;
    top: 2px;
    right: -2px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    text-align: center;
    font-size: 0.5rem !important;
    /* color: var(--white);
    background-color: var(--light-green); */
    border: 1px solid transparent;
}

/* for light theme starts */

.light .header {
    background-color: var(--white);
}

.light .signUp-button {
    color: var(--dark);
    border: 2px solid var(--golden);
    background-color: var(--golden);
}

.light .login-button {
    color: var(--white);
    border: 2px solid var(--chocolate);
    background-color: var(--chocolate);
}

.light .custom-amount-button {
    background-color: var(--light-blue);
    color: var(--dark);
}

.light .currency-icon {
    color: var(--white);
    background-color: var(--light-green);
}

.light .custom-amount-button .amount-deposit-button {
    background-color: var(--light-green);
}

.light .bet-notification-number span {
    color: var(--white);
    background-color: var(--light-green);
    border: 1px solid var(--gray);
}

/* for dark theme starts */

.dark .header {
    background-color: var(--blackish-ash);
}

.dark .signUp-button {
    color: var(--dark);
    border: 2px solid var(--golden);
    background-color: var(--golden);
}

.dark .login-button {
    color: var(--white);
    border: 2px solid var(--chocolate);
    background-color: var(--chocolate);
}

.dark .custom-amount-button {
    background-color: var(--light-blue);
    color: var(--dark);
}

.dark .currency-icon {
    color: var(--white);
    background-color: var(--light-green);
}

.dark .custom-amount-button .amount-deposit-button {
    background-color: var(--light-green);
}

.dark .bet-notification-number span {
    color: var(--white);
    background-color: var(--light-green);
    border: 1px solid var(--blackish-ash);
}

/* refresh button animation */

@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    to {
        -webkit-transform: rotate(1deg);
        -o-transform: rotate(1deg);
        transform: rotate(1deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    to {
        -ms-transform: rotate(1deg);
        -moz-transform: rotate(1deg);
        -webkit-transform: rotate(1deg);
        -o-transform: rotate(1deg);
        transform: rotate(1deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

/* blink image styles */

.blink-image {
    animation: animate 1.5s linear infinite;
}

@keyframes animate {
    0% {
        opacity: 1.0;
    }

    10% {
        opacity: 1.0;
    }

    20% {
        opacity: 0.7;
    }

    30% {
        opacity: 0.3;
    }

    40% {
        opacity: 0.1;
    }

    50% {
        opacity: 0.1;
    }

    60% {
        opacity: 0.1;
    }

    70% {
        opacity: 0.3;
    }

    80% {
        opacity: 0.7;
    }

    90% {
        opacity: 1.0;
    }

    100% {
        opacity: 1.0;
    }
}

.nav-user-bg {
    background-color: var(--dark);
    border-radius: 50%;
}

.launcher-nav-user .user-name {
    font-size: 0.8rem !important;
}

.launcher-nav-user .user-type {
    font-size: 0.6rem !important;
}

/* Animated Section */
.animated-section {
    position: absolute;
    left: 0;
    right: 0;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); */
    display: flex;
    justify-content: space-around;
    transition: top 0.5s ease;
}

.light .animated-section {
    background-color: var(--white);
}

.dark .animated-section {
    background-color: var(--black);
}

@media screen and (min-width: 320px) and (max-width: 420px) {

    .bet-notification-number span {
        font-size: 0.6rem !important;
    }

    .launcher-nav-user .user-name {
        font-size: 0.8rem !important;
    }

    .launcher-nav-user .user-type {
        font-size: 0.7rem !important;
    }
}