.App {
  text-align: center;
  display: flex;
  justify-content: center;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100dvh;
}

.centered-main-section {
  height: 100dvh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.centered-main-section-betby {
  height: 100dvh;
  /* overflow-x: hidden;
  overflow-y: hidden; */
  width: 100%;
}

/* for light theme starts */

.light .centered-main-section,
.light .centered-main-section-betby {
  background-color: var(--light-blue);
  color: var(--dark);
}

/* for dark theme starts */

.dark .centered-main-section,
.light .centered-main-section-betby {
  background-color: var(--dark);
  color: var(--white);
}

@media only screen and (min-width: 992px) {

  .centered-main-section {
    height: 100dvh;
    width: 33%;
  }
}

@media screen and (max-width: 319px) {
  * {
    font-size: 0.7rem !important;
  }

  img {
    aspect-ratio: 0.95;
  }
}

@media screen and (min-width: 320px) and (max-width: 359px) {
  * {
    font-size: 0.8rem !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 420px) {
  * {
    font-size: 0.9rem !important;
  }
}