.light .game-left-bar {
    /* background-color: var(--white); */
    background-color: var(--light-blue);
}

.dark .game-left-bar {
    background-color: var(--dark);
}

.game-left-bar .sqrBtn {
    width: auto;
    height: auto;
    font-size: 0.5rem !important;
    /* background-image: linear-gradient(transparent, transparent); */
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    padding: 0.8em !important;
    cursor: pointer;
    transition: 0.8s;
    /* white-space: nowrap; */
}

.light .game-left-bar .sqrBtn {
    background-image: linear-gradient(var(--white), var(--white));
}

.dark .game-left-bar .sqrBtn {
    background-image: linear-gradient(var(--blackish-ash), var(--blackish-ash));
}

.game-left-bar .sqrBtn:hover {
    opacity: 0.6;
}

.game-left-bar .active {
    width: auto;
    height: auto;
    font-size: 0.5rem !important;
    /* background-image: linear-gradient(var(--golden), var(--golden)); */
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    padding: 0.8em !important;
    cursor: pointer;
    transition: 0.8s;
}

.light .game-left-bar .active {
    background-image: linear-gradient(var(--golden), var(--golden));
}

.dark .game-left-bar .active {
    background-image: linear-gradient(var(--golden), var(--golden));
}

.game-left-bar img {
    height: 3em;
    width: auto;
    margin-bottom: 5px;
}

.game-left-bar p {
    margin: 0;
    padding: 0;
    /* font-size: 0.9em; */
}

.light .game-left-bar p {
    color: var(--dark);
}

.dark .game-left-bar p {
    color: var(--white);
}

.light .game-left-bar .active p {
    color: var(--dark);
}

.dark .game-left-bar .active p {
    color: var(--dark);
}

@media screen and (max-width: 420px) {
    .game-left-bar .sqrBtn p {
        font-size: 0.5rem !important;
    }

    .game-left-bar .active p {
        font-size: 0.5rem !important;
    }

    .game-left-bar img {
        height: 1.5em;
        width: auto;
    }
}