/* .custom-sweet-alert h4 {
    color: var(--light-green) !important;
} */

.custom-sweet-alert-title {
    font-size: 1.2rem;
}

.custom-sweet-alert-sub-title {
    font-size: 2.0rem;
}

/* from swal class */
.swal2-icon-content {
    font-size: 3.75em !important;
}


@media only screen and (min-width: 992px) {
    .swal2-show {
        width: 33% !important;
    }

    .custom-sweet-alert-title {
        font-size: 1.2rem !important;
    }

    .custom-sweet-alert-sub-title {
        font-size: 2.0rem !important;
    }
}

@media screen and (min-width: 360px) and (max-width: 420px) {

    .custom-sweet-alert-title {
        font-size: 1.2rem !important;
    }

    .custom-sweet-alert-sub-title {
        font-size: 2.0rem !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 359px) {

    .custom-sweet-alert-title {
        font-size: 1.2rem !important;
    }

    .custom-sweet-alert-sub-title {
        font-size: 2.0rem !important;
    }
}

@media screen and (max-width: 319px) {

    .custom-sweet-alert-title {
        font-size: 1.2rem !important;
    }

    .custom-sweet-alert-sub-title {
        font-size: 2.0rem !important;
    }
}