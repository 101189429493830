.custom-input,
.otp-group {
    /* background-color: transparent; */
    /* color: var(--dark); */
    padding-top: 7px;
}

.custom-input .module {
    position: relative;
    background-color: inherit;
    color: inherit;
    /* border: 1.5px solid var(--dark); */
    border-radius: 5px;
}

.custom-input .module .input-group,
.custom-input .module .form-select,
.custom-input .module .form-control,
.custom-input .module .input-group-text {
    background-color: inherit !important;
    /* color: var(--dark); */
    border-color: transparent !important;
    border-radius: inherit !important;
}

.module label {
    position: absolute;
    /* background-color: var(--gray); */
    top: -13px !important;
    left: 10px;
    color: inherit;
    z-index: 10;
    padding: 0px 10px;
    border-radius: 30px !important;
    font-size: 0.9rem;
    line-height: 1.1rem;
}

.custom-input .module input::placeholder {
    /* color: var(--gray); */
    opacity: 1;
}

.custom-input .module input:focus::placeholder {
    /* color: transparent; */
    opacity: 1;
}

.custom-input .module input:focus,
.custom-input .module select:focus,
.form-check-input:focus {
    box-shadow: none !important;
    outline: none !important;
}

.custom-input .form-select {
    -webkit-appearance: none !important;
    cursor: pointer;
}

.light .custom-input .form-select {
    /* filter: none; */
    --bs-form-select-bg-img: url("../../../../public/Assets/Images/Icons/SharedIcons/dropdown-icon-light.svg");
}

.dark .custom-input .form-select {
    /* filter: grayscale(100%); */
    --bs-form-select-bg-img: url("../../../../public/Assets/Images/Icons/SharedIcons/dropdown-icon-dark.svg");
}

.custom-input .module .custom-input-info {
    position: absolute;
    padding: 0 10px;
    top: -18px;
    right: 0;
    background-color: var(--gray);
    border-radius: 5px;
    z-index: 10;
    font-size: 0.7rem !important;
}

.custom-input .module .custom-input-error {
    position: absolute;
    padding: 0 10px;
    /* top: -14px; */
    bottom: -20px;
    right: 0;
    background-color: var(--gray);
    border-radius: 30px;
    /* color: red; */
    z-index: 10;
    font-size: 12px;
}

/* number type input field scroll off */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

input:focus {
    outline: none !important;
}


/* for light theme starts */

.light .custom-input,
.light .otp-group {
    background-color: transparent;
    color: var(--dark);
}

.light .custom-input .module {
    /* border: 1px solid var(--gray); */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.light .custom-input .module .input-group,
.light .custom-input .module .form-select,
.light .custom-input .module .form-control,
.light .custom-input .module .input-group-text {
    color: var(--dark);
}

.light .module label {
    color: var(--dark);
    background-color: var(--gray);
}

.light .custom-input .module input::placeholder {
    color: var(--gray);
}

.light .custom-input .module input:focus::placeholder {
    color: transparent;
}

.light .custom-input .module .custom-input-error {
    color: var(--red);
}


/* for dark theme starts */

.dark .custom-input,
.dark .otp-group {
    background-color: transparent;
    color: var(--dark);
}

.dark .custom-input .module {
    /* border: 1px solid var(--gray); */
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 2px 6px 2px;
}

.dark .custom-input .module .input-group,
.dark .custom-input .module .form-select,
.dark .custom-input .module .form-control,
.dark .custom-input .module .input-group-text {
    color: var(--white);
    background-color: var(--blackish-ash) !important;
}

.dark .custom-input .module .phone-code {
    color: var(--dark);
    background-color: var(--placeholder) !important;
}

.dark .module label {
    color: var(--dark);
    background-color: var(--gray);
}

.dark .custom-input .module input::placeholder {
    color: var(--gray);
}

.dark .custom-input .module input:focus::placeholder {
    color: transparent;
}

.dark .custom-input .module .custom-input-error {
    color: var(--red);
}