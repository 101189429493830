.light .bottom-nav-area {
    background-color: var(--gray);
}

.dark .bottom-nav-area {
    background-color: var(--blackish-ash);
}

.light .bottom-nav-area .nav-link,
.light .bottom-nav-area .nav-link:hover,
.dark .bottom-nav-area .nav-link,
.dark .bottom-nav-area .nav-link:hover {
    text-decoration: none;
    filter: none;
    padding: 5px 10px;
    border-radius: 10px;
    transition: 0.7s;
}

.light .bottom-nav-area .nav-active,
.dark .bottom-nav-area .nav-active {
    text-decoration: none;
    filter: none;
    padding: 5px 10px;
    border-radius: 10px;
    transition: 0.7s;
}

.light .bottom-nav-area .nav-active {
    color: var(--dark);
    background-color: var(--golden);
}

.dark .bottom-nav-area .nav-active {
    color: var(--white);
    background-color: var(--golden);
}


.bottom-nav-area a:link .active,
.bottom-nav-area a:link .nav-link {
    text-decoration: none;
}

.bottom-nav-area .active .active-bottom-nav-link {
    color: var(--white);
}