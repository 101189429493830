.light .game-title-area {
    border-left: 5px solid var(--white);
    border-radius: 3px;
}

.dark .game-title-area {
    border-left: 5px solid var(--blackish-ash);
    border-radius: 3px;
}

.light .game-title-bg {
    background-color: var(--white);
}

.dark .game-title-bg {
    background-color: var(--blackish-ash);
}

.game-title {
    text-align: center;
    padding: 0.1em 0;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
}

.light .game-title {
    color: var(--dark);
}

.dark .game-title {
    color: var(--white);
}

.dynamic-games-banner {
    height: 14dvh !important;
}

.search-column input {
    /* border: 1px solid var(--dark); */
    width: 40%;
    transition: width 0.3s;
}

.search-column input:focus {
    width: 100%;
}

.light .search-column input {
    border: 1px solid var(--dark);
    background-color: var(--white);
    color: var(--dark);
}

.dark .search-column input {
    border: 1px solid var(--white);
    background-color: var(--dark);
    color: var(--white);
}

.search-column .search-icon {
    opacity: 0.8;
    position: absolute;
    right: calc(35% - 15px);
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
}

.search-column:focus-within .search-icon {
    opacity: 0;
    right: calc(95% - 15px);
}

/* overlay */
.dynamic-games-overlay {
    position: absolute;
    top: 0;
    /* background-image: linear-gradient(to bottom, transparent 50%, var(--gray) 100%); */
    z-index: 2;
    width: 100%;
    height: 100%;
}

.light .dynamic-games-overlay {
    background-image: linear-gradient(to bottom, transparent 50%, var(--gray) 100%);
}

.dark .dynamic-games-overlay {
    background-image: linear-gradient(to bottom, transparent 50%, var(--dark) 100%);
}


@media screen and (max-width: 992px) {
    .dynamic-games-banner {
        height: 100px !important;
    }
}

@media screen and (min-width: 360px) and (max-width: 420px) {
    .dynamic-games-banner {
        height: 12dvh !important;
    }
}